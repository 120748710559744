<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h1>Time Tracker</h1>
        <v-spacer></v-spacer>
        <v-btn color="primary" to="/timetracker/reports">Create a report</v-btn>
        <!-- <v-btn to="/timetracker/manual" >Create manual entry</v-btn> -->
        <ManualTimeEntry />
      </v-card-title>

      <v-card-text>
          <TimeEntries :entries="entries" title="Time Entries" paginated=true></TimeEntries>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import TimeEntries from "@/components/time/TimeEntries";
import ManualTimeEntry from "@/components/time/ManualTimeEntry";

export default {
    components: {
        TimeEntries, ManualTimeEntry
    },
  data() {
    return {};
  },
  methods: {
  },
  computed: {
    entries() {
      return this.$store.getters.sortedTimes;
    }
  },
  mounted() {
    this.$store.dispatch("loadTimes");
  }
};
</script>
<style scoped>

</style>