<template>
  <v-container>
    <v-card  v-if="project">
      <v-card-title>
        <h2>{{ project.name }}

        <span class="client-name" v-if="project.client"
          >({{ project.client.name }})</span
        >
        </h2>

        <v-spacer></v-spacer>

        <v-menu dark>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list light>
            <EditProject v-if="project" :project="project" />
            <v-list-item v-if="status !== 'archived'" @click="archiveButton">
              <v-list-item-title>Archive Project</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="status == 'archived'" @click="deArchiveButton">
              <v-list-item-title
                >Bring Project out of Archive</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <p v-if="project.description">{{ project.description }}</p>
        <Tasks
          v-if="project"
          :title="project.name"
          :tasks="taskList"
          :project="project"
        ></Tasks>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { baseURL } from "@/components/http-common";
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import Tasks from "@/components/task/Tasks";
import EditProject from "@/components/project/EditProject";

export default {
  mixins: [validationMixin],

  validations: {
    name: {
      required,
      maxLength: maxLength(255),
      minLength: minLength(3),
    },
  },
  components: { Tasks, EditProject },
  data() {
    return {
      baseURL: baseURL,
      showEditor: false,
      name: "",
      status: "",
      showEditDialog: false,
    };
  },
  computed: {
    taskList() {
      return this.$store.getters.sortedTasks.filter((task) => {
        return task.project === this.project._id;
      });
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 255 characters long");
      !this.$v.name.minLength &&
        errors.push("Name must be at least 3 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    project() {
      return this.$store.state.projectStore.projects.find((project) => {
        return project._id === this.$route.params.id;
      });
    },
  },
  methods: {
    handleEdit() {
      this.$v.$touch();
      this.$refs.nameInput.focus();
      if (!this.$v.$anyError) {
        let payload = {
          id: this.project._id,
          name: this.name,
        };
        this.$store.dispatch("updateProject", payload);
        this.showEditor = false;
      } else {
        this.$store.dispatch("setError", {
          data: { message: "Name must be at least 3 characters" },
        });
      }
    },
    archiveButton() {
      let payload = {
        id: this.project._id,
        status: "archived",
      };
      this.$store.dispatch("updateProject", payload);
      this.$router.push({ path: "/projects" });
    },
    deArchiveButton() {
      let payload = {
        id: this.project._id,
        status: "active",
      };
      this.$store.dispatch("updateProject", payload);
      this.status = "active";
    },
    cancelEdit() {
      this.$v.$touch();
      this.name = this.project.name;
      this.showEditor = false;
    },
    deleteProject() {
      this.$store.dispatch("deleteProject", this.project._id);
      this.$router.push("/projects");
    },
    archiveProject() {
      alert("archive project");
    },
  },
  mounted() {
    this.name = this.project.name;
    this.status = this.project.status;
    this.$store.dispatch("loadTasks", this.project.id);
  },
};
</script>

<style scoped>
.project-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.project-grid img {
  max-height: 150px;
  width: auto;
}
.project-grid > div {
  border: 1px solid #e7e7e7;
}
.client-name {
  color: green;
  font-style: italic;
  font-size: 80%;
  margin-left: 12px;
}
#name-form {
  min-width: 400px;
}
</style>