<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h1>Projects</h1>
        <v-spacer></v-spacer>
        <v-btn to="/projects/add" small color="primary">Add Project</v-btn>
        <v-btn @click="toggleArchived" small>{{archivedText}}</v-btn>
        <!-- <v-btn :disabled="sortBy == 'name'" small @click="setSort('name')">Sort by Project Name</v-btn> -->
        <!-- <v-btn :disabled="sortBy == 'createdAt'" small @click="setSort('createdAt')">Sort by Date</v-btn> -->
        <!-- <v-btn small @click="toggleSortOrder()">Order: {{sortText}}</v-btn> -->
      </v-card-title>
      <v-card-text>
        <div class="search-box">
          <v-text-field
            prepend-icon="mdi-magnify"
            v-model="searchProjects"
            type="text"
            name="Search"
            id="searchproject"
            placeholder="Filter"
          />
        </div>
        <div class="project-grid">
          <v-card
          dense
            hover
            v-for="project in filteredProjects"
            :key="project._id"
            :to="'projects/' + project._id"
          >
            <!-- <v-card-title>{{ project.name }}</v-card-title> -->
            <v-card-text v-if="project.client">
                <h3>{{project.name}}</h3>
                {{project.client.name}}
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { baseURL } from "@/components/http-common";
export default {
  data() {
    return {
      baseURL: baseURL,
      searchProjects: ""
    };
  },
  computed: {
    sortText() {
      if (this.ascending) {
        return "ASC";
      } else {
        return "DESC";
      }
    },
    archivedText() {
      if (this.showArchived) {
        return "Hide Archived Projects";
      } else {
        return "Show Archived Projects";
      }
    },
    ascending() {
      return this.$store.state.projectStore.sortAscending;
    },
    sortBy() {
      return this.$store.state.projectStore.sortBy;
    },
    showArchived() {
      return this.$store.state.projectStore.showArchived;
    },
    filteredProjects() {
      const search = this.searchProjects.toLowerCase().trim();
      return this.projects.filter(
        project =>
          project.name.toLowerCase().indexOf(search) > -1 ||
          project.client.name.toLowerCase().indexOf(search) > -1 ||
          project.status.toLowerCase().indexOf(search) > -1
      );
    },
    projects() {
      return this.$store.getters.sortedProjects;
    }
  },
  methods: {
    toggleArchived() {
      this.$store.dispatch("toggleArchivedProjects");
    },
    toggleSortOrder() {
      this.$store.dispatch("toggleSortOrder");
    },
    setSort(payload) {
      this.$store.dispatch("setSortBy", payload);
    },
    getProjects() {
      this.$store.dispatch("loadProjects");
    }
  },
  mounted() {
    this.getProjects();
  }
};
</script>

<style scoped>
.project-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}
.search-box {
  max-width: 400px;
}
@media screen and (max-width: 1000px) {
  .project-grid {
    grid-template-columns: 1fr 1fr;
  }
}
</style>