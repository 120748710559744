<template>
  <v-card>
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
      <span v-if="subtitle" class="subtitle">Reporting: {{ subtitle }}</span>
    </v-card-title>
    <v-card-text>
      <p class="text-center" v-if="paginated">
        Page: {{ page }} of {{ numPages }}
      </p>

      <table id="time-entries-table">
        <tr>
          <th>Date</th>
          <th>Project</th>
          <th>Start/End</th>
          <th>Duration</th>
          <th class="action-menu"></th>
        </tr>
        <tr v-for="entry in entries" :key="entry._id">
          <td>{{ getDate(entry.startTime) }}</td>
          <td>
            <strong>{{ entry.project.name }}</strong>
            <span class="client-name">({{ entry.client.name }})</span><br />
            <span class="description">{{ entry.description }}</span>
          </td>
          <td>
            <span class="clock"
              >{{ getTime(entry.startTime) }}<br />{{
                getTime(entry.endTime)
              }}</span
            >
          </td>
          <td>
            <span class="clock duration"
              ><strong>{{ duration(entry) }}</strong></span
            >
          </td>
          <td class="action-menu">
            <div>
              <v-menu dark>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list color="orange darken-4" dense>
                  <!-- <v-list-item @click="editEntry(entry._id)">
                    <v-list-item-title>Edit time entry</v-list-item-title>
              </v-list-item>-->
                  <v-list-item @click="deleteEntry(entry._id)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </table>

      <div class="text-center" v-if="paginated">
        <v-pagination v-model="page" :length="numPages"></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { DateTime, Duration } from "luxon";

export default {
  props: ["entries", "title", "subtitle", "paginated"],
  data() {
    return { page: 1 };
  },
  mounted() {
    //   this.page = this.pagination.page;
    this.$store.dispatch("setPageNumber", this.page);
  },
  computed: {
    numPages() {
      return this.$store.getters.numPages;
    },
    loading() {
      return this.$store.state.loading;
    },
    pagination() {
      return this.$store.state.timeStore.pagination;
    },
  },
  methods: {
    deleteEntry(id) {
      this.$store.dispatch("deleteTime", id);
    },
    duration(entry) {
      let start = DateTime.fromISO(entry.startTime);
      let end = DateTime.fromISO(entry.endTime);
      let diff = end.diff(start).toObject();
      return Duration.fromObject(diff).toFormat("h:mm:ss");
    },
    getDate(dt) {
      let date = DateTime.fromISO(dt);
      return date.toFormat("LLLL dd, yyyy");
    },
    getTime(dt) {
      let time = DateTime.fromISO(dt);
      return time.toFormat("h:mm:ss a");
    },
  },
  watch: {
    page() {
      this.$store.dispatch("setPageNumber", this.page);
      this.$store.dispatch("loadTimes");
    },
  },
};
</script>
<style scoped>
.clock {
  font-family: monospace;
  color: gray;
}
.entry {
  border-bottom: 1px solid #e7e7e7;
  padding: 16px;
}
.duration {
  color: black;
}
.client-name {
  color: green;
  font-size: 12px;
  margin-left: 12px;
}
.description {
  font-style: italic;
  color: gray;
}
.subtitle {
  color: green;
  font-size: 12px;
  margin-left: 20px;
}
@media screen and (max-width: 800px) {
  .header {
    display: none;
  }
}

#time-entries-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

#time-entries-table td,
#time-entries-table th {
  border: 0px solid #ddd;
  padding: 4px;
}

#time-entries-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#time-entries-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}
.line-item {
  padding-bottom: 16px;
}
.action-menu {
    text-align: right;
    width: 30px;
}
</style>