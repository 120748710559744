<template>
  <v-card>
    <v-card-title>{{client.name}}</v-card-title>
    <v-card-text>
      <form id="form">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="6">
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              label="Client Name"
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="6">
            <v-radio-group               label="Client status:"
v-model="status" row>
              <v-radio label="Active" value="active"></v-radio>
              <!-- <v-radio label="Inactive" value="inactive"></v-radio> -->
              <v-radio label="Archived" value="archived"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="contactName"
              label="Contact Name"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="phone"
              label="Phone"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="email"
              :error-messages="emailErrors"
              label="E-mail"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="6">
            <v-text-field
              v-model="streetAddress"
              label="Street Address (billing)"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="6">
            <v-text-field
              v-model="city"
              label="City"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="province"
              label="Province"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="country"
              label="Country"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="postalCode"
              label="Postal Code"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          v-model="notes"
          label="Notes"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        ></v-text-field>
      </form>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="$v.$anyError || !$v.$anyDirty && !radioChanged" class="mr-4" @click="submit">Update</v-btn>
      <!-- <v-btn @click="$emit('closeEditor')">Discard Changes</v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  email
} from "vuelidate/lib/validators";

export default {
  name: "EditClient",
  props: { client: Object },
  mixins: [validationMixin],

  validations: {
    name: {
      required,
      maxLength: maxLength(100),
      minLength: minLength(3)
    },
    email: { email }
  },
  data() {
    return {
      name: this.client.name,
      email: this.client.email,
      contactName: this.client.contactName || "",
      phone: this.client.phone || "",
      streetAddress: this.client.streetAddress || "",
      city: this.client.city || "",
      province: this.client.province || "",
      country: this.client.country || "",
      postalCode: this.client.postalCode || "",
      notes: this.client.notes || "",
      status: this.client.status
    };
  },
  computed: {
    radioChanged() {
      return this.status !== this.client.status;
    },
    user() {
      return this.$store.getters.user;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 100 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      return errors;
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        let payload = {
          id: this.client._id,
          name: this.name,
          email: this.email,
          contactName: this.contactName,
          phone: this.phone,
          streetAddress: this.streetAddress,
          city: this.city,
          province: this.province,
          country: this.country,
          postalCode: this.postalCode,
          notes: this.notes,
          status: this.status
        };
        this.$store.dispatch("updateClient", payload);
        this.$emit("closeEditor");
      } else {
        this.$store.dispatch("setError", {
          data: { message: "Fix the highlighted fields and try again" }
        });
      }
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    }
  }
};
</script>

<style scoped>
#addproject {
  max-width: 600px;
}
#extra-info {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 20px;
}
</style>