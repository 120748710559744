<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h1>Clients</h1>
        <v-spacer></v-spacer>
        <v-btn to="/clients/add" small color="primary">Add Client</v-btn>
        <v-btn @click="toggleArchived" small>{{ archivedText }}</v-btn>
      </v-card-title>
      <v-card-text>
        <div class="search-box">
          <v-text-field
            prepend-icon="mdi-magnify"
            v-model="searchClients"
            type="text"
            name="Search"
            id="search"
            placeholder="Search"
          />
        </div>
        <div v-if="clients" class="client-grid">
          <v-card
            hover
            v-for="client in filteredClients"
            :key="client._id"
            :to="'clients/' + client._id"
          >
            <!-- <v-card-title>{{ client.name }}</v-card-title> -->
            <v-card-text>
              <h4><v-icon>mdi-account-circle</v-icon>{{ client.name }}</h4>
              <!-- Status: {{client.status}} -->
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { baseURL } from "@/components/http-common";
export default {
  data() {
    return {
      baseURL: baseURL,
      searchClients: "",
    };
  },
  computed: {
    filteredClients() {
      const search = this.searchClients.toLowerCase().trim();
      return this.clients.filter(
        (client) => client.name.toLowerCase().indexOf(search) > -1
      );
    },
    clients() {
      return this.$store.getters.sortedClients;
    },
    showArchived() {
      return this.$store.state.clientStore.showArchived;
    },
    archivedText() {
      if (this.showArchived) {
        return "Hide Archived Clients";
      } else {
        return "Show Archived Clients";
      }
    },
  },
  methods: {
    toggleArchived() {
      this.$store.dispatch("toggleArchivedClients");
    },
    getClients() {
      this.$store.dispatch("loadClients");
    },
  },
  mounted() {
    this.getClients();
  },
};
</script>

<style scoped>
.client-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}
.search-box {
  max-width: 400px;
}
</style>