<template>
  <v-container fluid>
    <div v-if="client">
      <v-card>
        <v-card-title>
          Client Profile
        </v-card-title>
        <v-card-text>
        <!-- <v-btn @click="archiveClient">Archive Client</v-btn><v-btn @click="deleteClient">Delete Client</v-btn> -->
      <EditClient v-if="client" :client="client" @closeEditor="editing = !editing" />
        </v-card-text>
      </v-card>

    </div>
  </v-container>
</template>

<script>
import { baseURL } from "@/components/http-common";
import EditClient from "@/components/client/EditClient";

export default {
  components: { EditClient: EditClient },
  data() {
    return {
      baseURL: baseURL,
      editing: false
    };
  },
  computed: {
    client() {
      return this.$store.state.clientStore.clients.find(client => {
        return client._id === this.$route.params.id;
      });
    }
  },
  methods: {
    deleteClient() {
      this.$store.dispatch("deleteClient", this.client._id);
      this.$router.push("/clients");
    },
    archiveClient() {
      alert("archive client");
      this.$router.push("/clients");
    }
  }
};
</script>

<style scoped>
.client-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.client-grid img {
  max-height: 150px;
  width: auto;
}
.client-grid > div {
  border: 1px solid #e7e7e7;
}
</style>