<template>
  <v-container>
    <form v-if="clients.length" id="addproject">
      <v-card>
        <v-card-title>Add New Project</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            label="Name"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          ></v-text-field>
          <v-autocomplete
            v-model="select"
            :items="clients"
            item-text="name"
            item-value="_id"
            :error-messages="selectErrors"
            label="Client"
            required
            @change="$v.select.$touch()"
            @blur="$v.select.$touch()"
          ></v-autocomplete>
          <v-text-field
            v-model="description"
            :error-messages="descriptionErrors"
            label="Description"
            @input="$v.description.$touch()"
            @blur="$v.description.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="this.$v.$anyError" class="mr-4" @click="submit">submit</v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    name: {
      required,
      maxLength: maxLength(255),
      minLength: minLength(3)
    },
    description: { maxLength: maxLength(255) },
    select: { required }
  },

  data: () => ({
    name: "",
    description: "",
    select: null
  }),
  computed: {
    clients() {
      return this.$store.getters.sortedClients;
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("Client is required");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 255 characters long");
      !this.$v.name.minLength &&
        errors.push("Name must be at least 3 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Description must be at most 255 characters long");
      return errors;
    }
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        let payload = {
          client: this.select,
          name: this.name,
          description: this.description
        };
        this.$store.dispatch("createProject", payload);
        this.$router.push("/projects");
      } else {
        this.$store.dispatch("setError", {
          data: { message: "Fix the highlighted fields and try again" }
        });
      }
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
    }
  },
  mounted() {
    this.$store.dispatch("loadClients");
  }
};
</script>

<style scoped>
#addproject {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

</style>