<template>
  <div class="add-task-container">
    <div class="description">
      <v-text-field
        @keydown.enter="addTask()"
        v-model="description"
        autofocus
        label="New task..."
      ></v-text-field>
    </div>
    <div class="project" v-if="!project">
      <v-autocomplete
        small
        v-model="select"
        label="Project"
        :items="projects"
        :item-text="(item) => item.name + ' - ' + item.client.name"
        item-value="_id"
      ></v-autocomplete>
    </div>
    <div class="priority">
      <v-select :items="priorityOptions" label="Priority" item-text="normal" v-model="priority"></v-select>
    </div>
    <v-btn class="ml-5 mr-5" @click="addTask()">Add</v-btn>
  </div>
</template>
<script>
export default {
  props: ["project"],
  data() {
    return {
      select: null,
      description: "",
      priority: "normal",
      priorityOptions: ["normal", "todo", "waiting"],
      complete: false,
    };
  },
  mounted() {
    if (this.project) {
      this.select = this.project._id;
    }
  },
  computed: {
    projects() {
      return this.$store.getters.sortedProjects;
    },
  },
  methods: {
    addTask() {
      let payload = {
        description: this.description,
        priority: this.priority,
        project: this.select,
      };
      this.$store.dispatch("createTask", payload);
      this.description = "";
      this.priority = "normal";
    },
  },
};
</script>
<style scoped>
.add-task-container {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e7e7e7;
  margin-bottom: 40px;
  border-radius: 10px;
}
.description {
  flex-grow: 1;
  margin-right: 10px;
}
.project {
  margin-left: 20px;
  margin-right: 20px;
}
</style>