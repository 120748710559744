<template>
  <v-dialog v-model="dialog" persistent max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="info" dark v-bind="attrs" v-on="on"> Add time entry </v-btn>
    </template>
    <form v-if="projects.length" id="add-time-entry">
      <v-card color="brown lighten-5">
        <v-card-title
          ><v-icon>mdi-calendar-clock</v-icon> Manual Time Entry</v-card-title
        >
        <v-card-text>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="6">
                  <v-autocomplete
                    v-model="select"
                    label="Project"
                    :items="projects"
                    :item-text="(item) => item.name + ' - ' + item.client.name"
                    item-value="_id"
                    @input="$v.select.$touch()"
                    @blur="$v.select.$touch()"
                    :error-messages="selectErrors"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="6">
                  <v-text-field
                    v-model="description"
                    :error-messages="descriptionErrors"
                    label="Description"
                    @input="$v.description.$touch()"
                    @blur="$v.description.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <strong>Date</strong>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="datePicker"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="datePicker"
                        label="Select the date for the entry"
                        readonly
                        v-on="on"
                        @input="$v.datePicker.$touch()"
                        @blur="$v.datePicker.$touch()"
                        :error-messages="datePickerErrors"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="datePicker" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(datePicker)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row justify="space-around" align="center">
                <v-col style="width: 350px; flex: 0 1 auto">
                  <h2>Start:</h2>
                  <div v-if="startPickerErrors.length">
                    <div
                      class="inline-error"
                      v-for="error in startPickerErrors"
                      v-bind:key="error"
                    >
                      {{ error }}
                    </div>
                  </div>

                  <v-time-picker
                    ampm-in-title
                    format="ampm"
                    v-model="startPicker"
                    :max="endPicker"
                    @input="$v.startPicker.$touch()"
                    @blur="$v.startPicker.$touch()"
                  ></v-time-picker>
                </v-col>
                <v-col style="width: 350px; flex: 0 1 auto">
                  <h2>End:</h2>
                  <div v-if="endPickerErrors.length">
                    <div
                      class="inline-error"
                      v-for="error in endPickerErrors"
                      v-bind:key="error"
                    >
                      {{ error }}
                    </div>
                  </div>
                  <v-time-picker
                    v-model="endPicker"
                    :min="startPicker"
                    ampm-in-title
                    format="ampm"
                    @input="$v.endPicker.$touch()"
                    @blur="$v.endPicker.$touch()"
                    :error-messages="endPickerErrors"
                  ></v-time-picker>
                  {{ endPicker }}
                </v-col>
              </v-row>
              <div class="duration-bar">
                <h2 class="text-center">
                  Total time: <span class="clock">{{ duration || 0 }}</span>
                </h2>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="this.$v.$anyError" class="mr-4" @click="submit"
                >submit</v-btn
              >
              <v-btn class="ml-4" @click="cancel">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { DateTime, Duration } from "luxon";

const afterStartTime = (value, vm) => value > vm.startPicker;

export default {
  mixins: [validationMixin],
  validations: {
    datePicker: { required },
    startPicker: { required },
    endPicker: { required, afterStartTime },
    description: { maxLength: maxLength(200) },
    select: { required },
  },
  components: {},
  data: () => ({
    startPicker: null,
    endPicker: null,
    description: "",
    select: null,
    datePicker: null,
    menu: false,
    dialog: false,
  }),
  computed: {
    formattedStart() {
      if (this.startPicker) {
        let time = DateTime.fromISO(this.datePicker + "T" + this.startPicker);
        return time;
      } else {
        return this.startPicker;
      }
    },
    formattedEnd() {
      if (this.endPicker) {
        let time = DateTime.fromISO(this.datePicker + "T" + this.endPicker);
        return time;
      } else {
        return this.endPicker;
      }
    },
    projects() {
      return this.$store.getters.sortedProjects;
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("Project is required");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.maxLength &&
        errors.push("Description must be at most 255 characters long");
      return errors;
    },
    startPickerErrors() {
      const errors = [];
      if (!this.$v.startPicker.$dirty) return errors;
      !this.$v.startPicker.required && errors.push("Start time required");
      return errors;
    },
    endPickerErrors() {
      const errors = [];
      if (!this.$v.endPicker.$dirty) return errors;
      !this.$v.endPicker.afterStartTime &&
        errors.push("End time should be after start time");
      !this.$v.endPicker.required && errors.push("End time required");
      return errors;
    },
    datePickerErrors() {
      const errors = [];
      if (!this.$v.datePicker.$dirty) return errors;
      !this.$v.datePicker.required && errors.push("Date is required");
      return errors;
    },
    duration() {
      if (this.formattedStart && this.formattedEnd) {
        let start = this.formattedStart;
        let end = this.formattedEnd;
        let diff = end.diff(start).toObject();
        return Duration.fromObject(diff).toFormat("h:mm:ss");
      } else return "";
    },
  },

  methods: {
    cancel() {
      this.clear();
      this.dialog = false;
    },
    getTime(dt) {
      let time = DateTime.fromISO(dt);
      return time.toFormat("hh:mm:ss");
    },
    getClient() {
      let index = this.projects.findIndex(
        (project) => project._id == this.select
      );
      let project = this.projects[index];
      return project.client._id;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.$store.dispatch("createTime", {
          startTime: this.formattedStart,
          endTime: this.formattedEnd,
          project: this.select,
          client: this.getClient(),
          description: this.description,
        });
        this.dialog = false;
        this.clear();
      } else {
        this.$store.dispatch("setError", {
          data: { message: "Fix the highlighted fields and try again" },
        });
      }
    },
    clear() {
      this.startPicker = null;
      this.endPicker = null;
      this.datePicker = null;
      this.select = null;
      this.menu = false;
      this.description = "";

      this.$v.$reset();
    },
  },
  mounted() {
    this.$store.dispatch("loadProjects");
  },
};
</script>

<style scoped>
.inline-error {
  font-size: 12px;
  color: red;
}
#add-time-entry {
  /* max-width: 600px; */
  margin-left: auto;
  margin-right: auto;
}
.clock {
  font-family: monospace;
}
.duration-bar {
  width: 100%;
  background-color: #3e2723;
  padding: 10px;
  color: white;
}
</style>