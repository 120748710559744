<template>
  <v-card :id="printId()">
    <v-card-title>
      <span class="project-name-header">{{ projectName }} </span>
      <span class="client-name-header"> ({{ clientName }})</span>
      <v-spacer />
      <span class="date-range"
        >{{ getDate(startDate) }} to {{ getDate(endDate) }}</span
      >
    </v-card-title>
    <v-card-text>
      <h1 class="text-right total-time">Total Time: {{ totalTime }}</h1>
      <v-btn
        v-if="!isPrinting"
        small
        color="primary"
        @click="showDetails = !showDetails"
        >{{ detailButtonText }}</v-btn
      >
      <v-text-field
        v-if="showDetails && !isPrinting"
        prepend-icon="mdi-magnify"
        v-model="searchDescription"
        type="text"
        name="Filter"
        placeholder="Filter by description"
      />
      <v-btn x-small @click="printTimeReport" v-if="showDetails && !isPrinting"
        ><v-icon left dark>mdi-printer</v-icon> Print</v-btn
      >
    </v-card-text>
    <TimeEntries
      v-if="showDetails"
      class="mt-5"
      title="Entries"
      :entries="filteredOnDescription"
    ></TimeEntries>
  </v-card>
</template>
<script>
import { DateTime, Duration } from "luxon";
import TimeEntries from "@/components/time/TimeEntries";

export default {
  props: ["entries", "projectName", "clientName", "startDate", "endDate"],
  data() {
    return {
      showDetails: false,
      searchDescription: "",
      isPrinting: false,
    };
  },
  components: { TimeEntries },
  computed: {
    detailButtonText() {
      if (!this.showDetails) {
        return "Show Details";
      } else {
        return "Hide Details";
      }
    },
    filteredOnDescription() {
      const search = this.searchDescription.toLowerCase().trim();
      return this.entries.filter(
        (entry) => entry.description.toLowerCase().indexOf(search) > -1
      );
    },
    totalTime() {
      let total = Duration.fromObject({});
      this.filteredOnDescription.forEach((entry) => {
        total = total.plus(this.duration(entry));
      });
      return total.toFormat("h:mm:ss");
    },
  },
  methods: {
    printId() {
      return this.projectName.split(" ").join("_");
    },
    prettyFileName() {
      let startdt = DateTime.fromISO(this.startDate);
      let enddt = DateTime.fromISO(this.endDate);
      let from = startdt.toISODate();
      let to = enddt.toISODate();
      let noSpaceProjectName = this.projectName.split(" ").join("_");
      let fileName = `TimeReport_${noSpaceProjectName}_${from}-${to}`;
      return fileName;
    },
    printTimeReport() {
      let pageTitle = document.title;
      document.title = this.prettyFileName();
      this.isPrinting = true;
      setTimeout(() => {
        this.$htmlToPaper(this.printId(), null, () => {
          document.title = pageTitle;
          this.isPrinting = false;
        });
      }, 500);
    },
    getDate(dt) {
      let date = DateTime.fromISO(dt);
      return date.toFormat("LLLL d, yyyy");
    },
    duration(entry) {
      let start = DateTime.fromISO(entry.startTime);
      let end = DateTime.fromISO(entry.endTime);
      let diff = end.diff(start).toObject();
      return Duration.fromObject(diff);
    },
  },
};
</script>
<style scoped>
.total-time {
  color: green;
}
.date-range {
  color: gray;
  font-weight: normal;
}

.client-name {
  color: gray;
  font-weight: normal;
  font-size: 95%;
  margin-left: 10px;
}

</style>