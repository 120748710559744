<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title>Edit Details</v-list-item-title>
      </v-list-item>
    </template>
    <form v-if="clients.length" id="addproject">
      <v-card>
        <v-card-title>Edit Project Details - {{ project.name }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            label="Name"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          ></v-text-field>
          <v-autocomplete
            v-model="select"
            :items="clients"
            item-text="name"
            item-value="_id"
            :error-messages="selectErrors"
            label="Client"
            required
            @change="$v.select.$touch()"
            @blur="$v.select.$touch()"
          ></v-autocomplete>
          <v-textarea
            v-model="description"
            :error-messages="descriptionErrors"
            label="Description"
            @input="$v.description.$touch()"
            @blur="$v.description.$touch()"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="this.$v.$anyError" class="mr-4" @click="update"
            >update</v-btn
          >
          <v-btn class="mr-4" @click="dialog = false">cancel</v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    name: {
      required,
      maxLength: maxLength(255),
      minLength: minLength(3),
    },
    description: { maxLength: maxLength(255) },
    select: { required },
  },
  props: ["project"],
  data() {
    return {
      name: "",
      description: "",
      select: null,
      dialog: false,
    };
  },
  computed: {
    clients() {
      return this.$store.getters.sortedClients;
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("Client is required");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 255 characters long");
      !this.$v.name.minLength &&
        errors.push("Name must be at least 3 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Description must be at most 255 characters long");
      return errors;
    },
  },

  methods: {
    update() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        let payload = {
          id: this.project._id,
          client: this.select,
          name: this.name,
          description: this.description,
        };

        this.$store.dispatch("updateProject", payload);
        this.dialog = false;
      } else {
        this.$store.dispatch("setError", {
          data: { message: "Fix the highlighted fields and try again" },
        });
      }
    },
    // clear() {
    //   this.$v.$reset();
    //   this.name = "";
    //   this.email = "";
    //   this.select = null;
    // },
  },
  mounted() {
    this.$store.dispatch("loadClients");
    this.name = this.project.name || "";
    this.description = this.project.description || "";
    this.select = this.project.client._id;
  },
};
</script>

<style scoped>
</style>