<template>
  <v-card>
    <v-card-title
      >Tasks: <em>{{ title }}</em></v-card-title
    >
    <v-card-text>
      <AddTask :project="project" />
      <div class="task-list">
        <div class="task-row" v-for="task in tasks" :key="task._id">
          <Task :task="task"></Task>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Task from "@/components/task/Task";
import AddTask from "@/components/task/AddTask";

export default {
  props: ["title", "tasks", "project"],
  components: { Task, AddTask },
  computed: {
    // sortedTasks() {
    //   const sortOrder = ["todo", "normal", "waiting"];
    //   let newArr = [...this.tasks];
    //   newArr.sort(function (a, b) {
    //     return sortOrder.indexOf(a.priority) - sortOrder.indexOf(b.priority);
    //   });
    //   return newArr;
    // },
  },
  methods: {

  },
};
</script>
<style scoped>
</style>>

